import api from '@/core/services/api.service.js'
api.setHeader()

const CrudModule = {
  
  async setSelectOption(route, slug = "") {
    let arr = [], state = ''
    await api.get(route, slug)
      .then((response) => {
        // //('response', response)
        state = 'success'
        response.data.data.forEach((value) => {
          arr.push({ text: value.name, value: value.id })
        })
      }).catch((error) => {
        //('error', errorget)
      })
    return {
      state: state,
      data: arr
    }
  },

  async setTreeSelect(route, slug = "") {
    let arr = [], state = ''
    await api.get(route, slug)
      .then((response) => {
        // //('response', response)
        state = 'success'
        response.data.data.forEach((value) => {
          arr.push({ label: value.name, id: value.id })
        })
      }).catch((error) => {
        //('error', error)
      })
    return {
      state: state,
      data: arr
    }
  },

  async setTreeSelectValueName(route, slug = "") {
    let arr = [], state = ''
    await api.get(route, slug)
      .then((response) => {
        // //('response', response)
        state = 'success'
        response.data.data.forEach((value) => {
          arr.push({ label: value.name, id:value.name })
        })
      }).catch((error) => {
        //('error', error)
      })
    return {
      state: state,
      data: arr
    }
  },

  async setTreeSelectToddler(route, slug = "") {
    let arr = [], state = ''
    await api.get(route, slug)
      .then((response) => {
        // //('response', response)
        state = 'success'
        response.data.data.forEach((value) => {
          arr.push({ label: value.registration_number + '-' + value.toddler_name + '-' + value.mother_name, id: value.id })
        })
      }).catch((error) => {
        //('error', error)
      })
    return {
      state: state,
      data: arr
    }
  },

  async setTreeSelectCompetenceStandard(route, slug = "") {
    let arr = [], state = ''
    await api.get(route, slug)
      .then((response) => {
        // //('response', response)
        state = 'success'
        response.data.data.forEach((value) => {
          arr.push({ label: value.code + ' ' + value.name, id: value.id })
        })
      }).catch((error) => {
        //('error', error)
      })
    return {
      state: state,
      data: arr
    }
  },

  async setTreeSelectQuiz(route, slug = "") {
    let arr = [], state = ''
    await api.get(route, slug)
      .then((response) => {
        // //('response', response)
        state = 'success'
        response.data.data.forEach((value) => {
          arr.push({ label: value.title, id: value.id })
        })
      }).catch((error) => {
        //('error', error)
      })
    return {
      state: state,
      data: arr
    }
  },

  async setTreeSelectTitle(route, slug = "") {
    let arr = [], state = ''
    await api.get(route, slug)
      .then((response) => {
        // //('response', response)
        state = 'success'
        response.data.data.forEach((value) => {
          arr.push({ label: value.title, id: value.id })
        })
      }).catch((error) => {
        //('error', error)
      })
    return {
      state: state,
      data: arr
    }
  },

  async setTreeSelectSchoolYear(route) {
    let arr = [], state = ''
    await api.get(route)
      .then((response) => {
        // //('response', response)
        state = 'success'
        response.data.data.forEach((value) => {
          arr.push({ label: value.start_year, id: value.id })
        })
      }).catch((error) => {
        //('error', error)
      })
    return {
      state: state,
      data: arr
    }
  },

  async setTreeSelectTeacher(route, slug = "") {
    let arr = [], state = ''
    await api.get(route, slug)
      .then((response) => {
        // //('response', response)
        state = 'success'
        response.data.data.forEach((value) => {
          arr.push({ label: value.name, id: value.id })
        })
      }).catch((error) => {
        //('error', error)
      })
    return {
      state: state,
      data: arr
    }
  },

  async setTreeSelectLesson(route) {
    let arr = [], state = ''
    await api.get(route)
      .then((response) => {
        // //('response', response)
        state = 'success'
        response.data.data.forEach((value) => {
          arr.push({ label: value.title, id: value.id })
        })
      }).catch((error) => {
        //('error', error)
      })
    return {
      state: state,
      data: arr
    }
  },

  async submit(data, route) {
    let state = "", validationError = "", success = ""
    // SwalLoading
    SwalLoading.fire()
    await api.post(route, data)
      .then((response) => {
        // //('response', response)
        state = 'success'
        success = response.data
      }).catch((error) => {
        //('error', error)
        if (error.response.status == 422) {
          state = 'error'
          validationError = error.response.data
        }
      }).finally(() => {
        // Close SwalLoading
        SwalLoading.close()
      })
    return {
      state: state,
      error: validationError,
      success: success,
    }
  },

  async submitWithoutAlert(data, route) {
    let state = "", validationError = "", success = ""
    // SwalLoading
    // SwalLoading.fire()
    await api.post(route, data)
      .then((response) => {
        // //('response', response)
        state = 'success'
        success = response.data
      }).catch((error) => {
        //('error', error)
        if (error.response.status == 422) {
          state = 'error'
          validationError = error.response.data
        }
      }).finally(() => {
        // Close SwalLoading
        // SwalLoading.close()
      })
    return {
      state: state,
      error: validationError,
      success: success,
    }
  },

  async submitUpdate(data, route) {
    let state = "", validationError = "", success = ""
    // SwalLoading
    SwalLoading.fire()
    await api.put(route, data)
      .then((response) => {
        // //('response', response)
        state = 'success'
        success = response.data
      }).catch((error) => {
        //('error', error)
        if (error.response.status == 422) {
          state = 'error'
          validationError = error.response.data
        }
      }).finally(() => {
        // Close SwalLoading
        SwalLoading.close()
      })
    return {
      state: state,
      error: validationError,
      success: success,
    }
  },

  async submitParticipant(data, route) {
    let state = "", validationError = "", success = ""
    // SwalLoading
    // SwalLoading.fire()
    await api.post(route, data)
      .then((response) => {
        // //('response', response)
        state = 'success'
        success = response.data
      }).catch((error) => {
        //('error', error)
        if (error.response.status == 422) {
          state = 'error'
          validationError = error.response.data
        }
      })
    return {
      state: state,
      error: validationError,
      success: success,
    }
  },

  async submitWithoutAlert(data, route) {
    let state = "", validationError = "", success = ""
    // SwalLoading
    // SwalLoading.fire()
    await api.post(route, data)
      .then((response) => {
        // //('response', response)
        state = 'success'
        success = response.data
      }).catch((error) => {
        //('error', error)
        if (error.response.status == 422) {
          state = 'error'
          validationError = error.response.data
        }
      })
    return {
      state: state,
      error: validationError,
      success: success,
    }
  },

  async submitUpdateWithoutAlert(data, route) {
    let state = "", validationError = "", success = ""
    // SwalLoading
    // SwalLoading.fire()
    await api.put(route, data)
      .then((response) => {
        // //('response', response)
        state = 'success'
        success = response.data
      }).catch((error) => {
        //('error', error)
        if (error.response.status == 422) {
          state = 'error'
          validationError = error.response.data
        }
      })
    return {
      state: state,
      error: validationError,
      success: success,
    }
  },

  async list(route, slug = "") {
    let data = {}
    await api.get(route, slug)
      .then((response) => {
        // //('response', response)
        data = response.data.data
        for (let a = 0; a < data.length; a++) {
          data[a].number = a + 1
        }
      }).catch((error) => {
        //('error', error)
      })
    return data
  },

  async paginate(route, slug = "") {
    let data = {}, meta = {}
    await api.get(route, slug)
      .then((response) => {
        data = response.data.data
        console.log(response)
        // for (let a = 0; a < data.length; a++) {
        //   data[a].number = a + 1
        // }
        meta = response.data.meta
      }).catch((error) => {
        //('gagal', error.response)
        //('error', error)
      })
    return { data: data, meta: meta }
  },

  async getSchedules(route, slug) {
    // //('slug=-======', slug)
    // //('route', route)
    let data = {}, meta = {}
    await api.get(route, slug)
      .then((response) => {
        // //('response', response)
        data = response
      }).catch((error) => {
        //('error', error)
      })
    return data
  },

  async get(route, params = "") {
    let data = null
    await api.get(route, params)
      .then((response) => {
        console.log(response)
        // If Data Exist
        if (response.status != 204) {
          data = response.data.data
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1
          }
        }
      }).catch((error) => {
        //('error', error)
      })
    return data
  },

  async getWithoutNumber(route, params = "") {
    let data = null
    await api.get(route, params)
      .then((response) => {
        // If Data Exist
        if (response.status != 204) {
          data = response.data.data
        }
      }).catch((error) => {
        //('error', error)
      })
    return data
  },

  async getTotalToddler(route, params = "") {
    let data = null
    await api.get(route, params)
      .then((response) => {
        // If Data Exist
        if (response.status != 204) {
          data = response.data
        }
      }).catch((error) => {
        //('error', error)
      })
    return data
  },

  async getReport(route, params = "") {
    let data = null
    await api.get(route, params)
    .then((response) => {
        // If Data Exist
        if (response.status != 204) {
          data = response.data
        }
      }).catch((error) => {
        //('error', error)
      })
    return data
  },

  async getMaterialCurriculum(route, params = "") {
    let data = null
    await api.get(route, params)
      .then((response) => {
        // //('response', response)
        // If Data Exist
        if (response.status != 204) {
          data = response
        }
      }).catch((error) => {
        //('error', error)
      })
    return data
  },

  async getDetailClassroom(route, params = "") {
    let data = null
    let response = null
    await api.get(route, params)
      .then((response) => {
        // //('response', response)
        // If Data Exist
        if (response.status != 204) {
          data = response.data.data
          response = response.data.data
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1
          }
        }
      }).catch((error) => {
        //('error', error)
      })
    return response
  },

  async classworkQuizScore(route, params = "") {
    let data = null
    let response = null
    await api.get(route, params)
      .then((response) => {
        // //('response', response)
        // If Data Exist
        if (response.status != 204) {
          data = response.data.classwork_quiz_scores[0]
        }
      }).catch((error) => {
        //('error', error)
      })
    return data
  },

  async getFullResponse(route, params = "") {
    let data = null
    await api.get(route, params)
      .then((response) => {
        // //('response', response)
        // If Data Exist
        if (response.status != 204) {
          data = response
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1
          }
        }
      }).catch((error) => {
        //('error', error)
      })
    return data
  },

  async delete(route) {
    let result = await Swal.fire({
      title: 'Hapus Data?',
      text: 'Data yang dihapus tidak bisa dikembalikan',
      icon: 'question',
      showCancelButton: true
    }).then(async function (result) {
      if (result.value) {
        // SwalLoading
        SwalLoading.fire()
        // Delete Data
        return await api.delete(route)
          .then((response) => {
            // //('response', response)
            // Success SweetAlert
            Swal.fire(response.data.title, response.data.message, 'success')
            return true
          }).catch((error) => {
            //('error', error)
            // Close SwalLoading
            SwalLoading.close()
            return false
          })
      }
    })
    return result
  },

  async deleteComment(route) {
    let result = await Swal.fire({
      title: 'Hapus Data?',
      text: 'Data yang dihapus tidak bisa dikembalikan',
      icon: 'question',
      showCancelButton: true
    }).then(async function (result) {
      if (result.value) {
        // SwalLoading
        SwalLoading.fire()
        // Delete Data
        return await api.delete(route)
          .then((response) => {
            // //('response', response)
            // Success SweetAlert
            Swal.fire(response.data.title, response.data.message, 'success')
            return response
          }).catch((error) => {
            //('error', error)
            // Close SwalLoading
            SwalLoading.close()
            return false
          })
      }
    })
    return result
  },

  async deleteWithResponse(route) {
    let result = await Swal.fire({
      title: 'Hapus Data?',
      text: 'Data yang dihapus tidak bisa dikembalikan',
      icon: 'question',
      showCancelButton: true
    }).then(async function (result) {
      if (result.value) {
        // SwalLoading
        SwalLoading.fire()
        // Delete Data
        return await api.delete(route)
          .then((response) => {
            // //('response', response)
            // Success SweetAlert
            Swal.fire(response.data.title, response.data.message, 'success')
            return response
          }).catch((error) => {
            //('error', error)
            // Close SwalLoading
            SwalLoading.close()
            return false
          })
      }
    })
    return result
  },

  async getAccessByUser(route, params = "") {
    let data = null
    await api.get(route, params)
      .then((response) => {
        // //('response', response)
        // If Data Exist
        if (response.status != 204) {
          data = response.data
          for (let a = 0; a < data.length; a++) {
            data[a].number = a + 1
          }
        }
      }).catch((error) => {
        //('error', error)
      })
    return data
  },

}

export default CrudModule